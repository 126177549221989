body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
}

p{
  padding: 0;
  margin: 0;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

:root {
  --sidebar-width: 42px;
  --sidebar-active-width: 185px;
  --sidebar-font-color: fff;
  --sidebar-font-size: 0.625rem;
  --sidebar-selected-color: #5a7c90;
  --sidebar-hover-color: #547689;
  --sidebar-background-color: #44677b;
  --sidebar-padding: 12px;
  --sidebar-slide-time: 200ms;

  --header-height: 50px;

  --attribute-smart-filter-container-width: 210px;
}

/**
 Falback for bootstrap  important for login screen to work right now
 remove once the login screen is fixed
*/

.d-md-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-primary {
  background-color: #4f677b;
  color: #fff;
}

.button-primary:hover {
  background-color: #4f677b;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

