body.modalOpen {
  overflow: hidden;
}

body.modalOpen .layout {
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
  width: 75%;
}

.main-content {
  flex: 1;
  /* padding: 25px; */
}

.cluster-icon svg *{
  fill: #fff !important;
}

