/* --------------- Global filter styles --------------- */
@import "Styles/variables.scss";
@import "Styles/mixins.scss";

label.required::after {
  content: "*";
  color: $alizarinCrimson;
}

/* ----- dropdown -------- */
.dropdown-wrapper {
  .dropdown-button {
    align-items: center;
    background: $white;
    border: 1px solid $silverChalice;
    border-radius: pxToRem(3);
    color: $codGray;
    cursor: pointer;
    display: inline-flex;
    flex-wrap: nowrap;
    margin: 0px;
    max-width: 100%;
    padding: 0.5rem 0 0.4rem 1.25rem;
    text-align: left;
    text-decoration: none;
    transition: background 0.1s ease-out 0s;
    vertical-align: middle;
    -webkit-box-align: center;
    white-space: nowrap;
    width: 100%;

    @include font-size-body1();
    line-height: normal;
  }

  .dropdown-button-disabled {
    background-color: $gallery;
    color: $darkSilverChalice;
  }

  span.selected-text {
    align-self: center;
    flex: 1 1 auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: "0.8rem";
    letter-spacing: "0rem";
    font-weight: normal;
    font-stretch: normal;
    color: $lightblack;
    opacity: 1;
  }

  span.toggle-icon {
    align-self: center;
    display: flex;
    margin-right: 0.5rem;
  }
}

/* ---- fixes for issues in react-select library ----- */
input[id^="react-select-"] {
  opacity: 1 !important;
}

/* ------ products group styles for dropdown menu ------ */
.multi-select-container {
  .ScrollCheck {
    overflow-y: auto;
    max-height: pxToRem(280);
    display: block;
  }
  .multi-select__control--menu-is-open,
  .multi-select__control {
    display: none; //hide value container and provide custom search bar
  }

  .multi-select__menu {
    box-shadow: none;

    .multi-select__menu-list {
      box-shadow: 0px 0px 0px 1px #0000001a;
      padding: 0;

      .multi-select__option {
        position: relative;
        color: $slateGrayLight;
        padding: 0;
        margin: 0;

        .checkbox {
          display: flex;
          font-weight: 400;
          align-items: center;
          padding: 0.5rem 1.25rem;
          cursor: pointer !important;

          .MuiCheckbox-root {
            margin-right: 0.25rem;
            padding: 0;

            .MuiSvgIcon-root {
              font-size: 1.25rem;
            }
          }
          input[type="checkbox"] {
            cursor: pointer !important;
          }
          label {
            margin-bottom: 0;
            padding-left: 4px;
            cursor: pointer !important;
          }
          &:before {
            bottom: 0;
            width: 12px;
            margin-left: -12px;
            height: 16px;
            content: "";
            color: #eaeaea;
            position: absolute;
          }
        }

        &--is-focused .checkbox{
          background-color: $endavour;
          color: $white;
        }

        :last-child {
          .checkbox {
            &:before {
              top: 0;
              border-top: none;
            }
          }
        }
      }

      .multi-select__option--is-selected {
        .checkbox {
          background-color: $endavour;
          color: $white;

          label {
            &:before {
              background-color: #0479fa !important;
            }

            &:after {
              border-bottom: 2px solid white;
              border-left: 2px solid white;
            }
          }
        }
      }

      &--is-multi .multi-select__option--is-selected {
        .checkbox {
          background-color: $white;
          color: $slateGrayLight;
        }
      }
    }
  }
}

.filter-options-group {
  .filter-options-item {
    display: flex;
    @include font-size-subtitle1();
    padding: 0.5rem 1.25rem;
    align-items: center;
    color: $slateGrayLight;

    &-icon {
      align-self: center;
      display: flex;
      margin-right: 0.5rem;

      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }

    &-text {
      margin-right: pxToRem(10);
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:hover {
        background-color: $selago;
      }
    }
  }
}

//custom search bar code
.menulistScroll {
  max-height: unset !important;
  overflow: unset !important;
}

.search-select__control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid $gallery;
  border-radius: 3px;
  cursor: default;
  color: $slateGrayLight;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  outline: 0 !important;
  padding: 0.5rem 1.25rem;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;

  .search-select__value-container {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;

    .multi-select__input {
      input {
        border: 0;
        opacity: 1;
        outline: 0;
        @include font-size-body1();
        line-height: normal;

        &:placeholder {
          color: $tiara;
        }
      }
    }
  }

  .multi-select__indicators {
    align-items: center;
    display: flex;
    margin-right: 0.125rem;
  }
}

.single-select-selected-option {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
