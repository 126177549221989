
.filter-text{
    padding: 1% 0.5%;
    text-align: left;
    margin: 1%;
  }
  
  .filter-btn{
    margin: 10px;
    color: azure;
    padding: 0.5% 2%;
    background-color: #44677b;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .filter-form{
    text-align: center;
    margin: 10%;
    flex-flow: row;
  }


.filter-inp {
  padding: 5px;
  text-align: left;
  width: 30%;
  background-color: #fff;
}
.input-group {
  text-align: left;
  margin-bottom: 1rem;
}