.MuiInputLabel-asterisk {
    color: red;
    float: right;
    top: 0%;
    margin-right: 3%;
}
.MuiInputLabel-root{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
}
.dropdown-label-padding{
    padding-left: "2%"
}
.DateInput_input__disabled,.DateRangePickerInput__disabled{
    background: #EFEFEF !important;
    color: #AFAFAF !important;
}
.DateRangePickerInput {
    position: relative;
}