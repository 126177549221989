@import "Styles/variables.scss";
@import "Styles/mixins.scss";


.DateRangePicker {
  width: 100%;

  &Input {
    width: 100%;
  }

  .DateInput_input__focused {
    border-color: $endavour;
  }

  .DateRangePickerInput {
    &_calendarIcon {
      padding: 0.375rem 0.125rem;
    }

    &__disabled .DateRangePickerInput_clearDates {
      display: none;
    }
  }

  .DateInput_input {
    padding: 0.125rem 0.5rem;
  }
}

.DateRangePicker_picker {
  z-index: 1301;

  .CalendarMonth_table {
    border-collapse: separate;
    margin-top: 0.5rem;
  }

  .DayPicker_weekHeader_li small {
    color: $fiord;
    @include font-size-subtitle1();
  }

  .DayPickerNavigation_button_1 {
    left: 1.5rem;

    &:nth-child(2) {
      left: unset;
      right: 1.5rem;
    }

    &:hover, &:active {
      background-color: rgba(29, 29, 29, 0.04);
      border-radius: 50%; 
    }
  }

  .DayPickerNavigation_button__horizontal {
    position: absolute;
    top: 1.25rem;
    line-height: 0.6;
    padding: 0.25rem;
  }

  .CalendarDay__default {
    border: 0.125rem solid $white;
    border-radius: 50%;
    color: $codGray;
    @include font-size-subtitle1();

    &:hover:not(.CalendarDay__selected) {
      background-color: rgba(29, 29, 29, 0.04);
    }
  }

  .CalendarDay__selected {
    background-color: $endavour;
    color: $white;

    &:hover, &:active {
      background-color: $endavour;
      color: $white;
    }

    &_span {
      background-color: $linkWater;
    }
  }

  .CalendarDay__hovered_span {
    background-color: $selago;

    &:hover, &:active {
      background-color: $selago;
    }
  }

  .CalendarDay__blocked_out_of_range {

    &:hover, &:active {
      background-color: $selago;
    }
  }

  .CalendarDay__blocked_out_of_range:not(.CalendarDay__selected) {
    color: $slateGrayLight;

    &:hover, &:active {
      background-color: $white;
      color: $slateGrayLight;
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right-color: $endavour;
  }
}
