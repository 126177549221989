.domain-alert-container {
  margin-top: 20px;
  min-height: 100vh;
}

.alert-display-style {
  display: flex;
  justify-content: center;
}

.welcome-text {
  font-weight: 500;
}

.hr-text {
  font-weight: normal;
  line-height: 1.25rem;
}

.title-text {
  font-weight: 600;
  color: #4f677b;
}
