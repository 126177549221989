.notes-sections {
    font-size: 24px;
}
.text {
    font-size: 14px;
    line-height: 1.5;
}
.indent-text {
    margin-left: 2%
}
.map-heading {
    font-size: 20px;
}
.heading-level-1 {
    font-size: 20px;
    color: #2f5496
}
.heading-level-2 {
    font-size: 18px;
}
.heading-level-3 {
    font-size: 16px;
    margin-left: 2%;
}

.release-notes-footer {
    color: #fff;
}

.release-notes-header {
    background-color: #091927;
    color: #fff;
    font-size: 28px;
    padding: 2%;
}

.release-notes-highlights {
    background-color: #001d3d;
    padding: 2%;
}

.links-text, .links-text:visited {
    color:#fff
}

.release-notes-facts {
    background-color: #003566;
    padding: 2%;
}

.social-icons-section {
    background-color: #081523;
    padding: 2%;
}

.content-map{
    line-height: 2;
}

.content-container {
    padding: 2%;
    border: 1px solid;
}
.logo {
    background-image: url("../../assets/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    width: 200px;
}

.social-media-icon {
    color: #fff;
    height: 50px !important;
    width: 50px !important;
}

.map-headings-container {
    background-color: #e7e6e6;
    position: sticky;
    top: 0;
    padding: 2%;
}